import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import CN from './cn'
import EN from './en'
import TW from './tw'

const resources = {
    en:{
        translation:EN
    },
    cn:{
        translation:CN
    },
    tw:{
        translation:TW
    },
}

i18n.use(initReactI18next).init({
    resources,
    lng:'en',
    interpolation:{
        escapeValue:false
    }
})

export default i18n