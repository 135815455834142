import { FC } from "react";
import { Outlet } from "react-router-dom";
import Navigation from "./components/Navigation";
// import Footer from "./components/Footer";
import AuthModal from "./screens/authmodal/AuthModal";

const TemplateFront: FC = () => {
  return (
    <div className="h-screen flex flex-col">
      <AuthModal />
      <Navigation />
      <Outlet />
      {/* <Footer /> */}
    </div>
  );
};
export default TemplateFront;
