import { createSlice } from "@reduxjs/toolkit";
import i18n from "../../langs/i18n";

interface initialStateProps {
  currentLang: string;
}
if (!localStorage.getItem("langs")) {
  localStorage.setItem(
    "langs",
    JSON.stringify({
      currentLang: "en",
    })
  );
}
const langs = JSON.parse(localStorage.getItem("langs")!);

i18n.changeLanguage(langs.currentLang);

const initialState: initialStateProps = {
  currentLang: langs.currentLang,
};
const langsSlice = createSlice({
  name: "langs",
  initialState,
  reducers: {
    set(state, action) {
      localStorage.setItem(
        "langs",
        JSON.stringify({
          currentLang: action.payload,
        })
      );
      state.currentLang = action.payload;
    },
  },
});

export const langsActions = langsSlice.actions;
export default langsSlice;
