import { FC, useState } from "react";
import { BsCart3 } from "react-icons/bs";
import Logo from "../../image/logo.png";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { authActions } from "../../store/auth/auth.slice";
import { HiOutlineMenu } from "react-icons/hi";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { langsActions } from "../../store/langs/langs.slice";
import i18n from "../../langs/i18n";

const dropDownStyle = "absolute top-0 w-full h-screen bg-white px-10 py-5";

const Navigation = () => {
  const { currentLang } = useAppSelector((state) => state.langs);
  const [mobileDrop, setMobileDrop] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleClickDrop = () => {
    setMobileDrop(!mobileDrop);
  };
  const handleClickLang = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const lang = e.currentTarget.value;
    dispatch(langsActions.set(lang));
    i18n.changeLanguage(lang);
  };
  const handlerMobbileClickLang = (e: React.MouseEvent<HTMLButtonElement>) => {
    const lang = e.currentTarget.dataset.lang;
    dispatch(langsActions.set(lang));
    i18n.changeLanguage(lang);
    setMobileDrop(!mobileDrop);
  }

  return (
    <>
      {/* desktop mode*/}
      <div className="hidden bg-[#ffffff] lg:flex justify-around text-[color:var(--text-primary)] py-5 px-[10%]   fixed top-0 w-full backdrop-[50px] z-50 ">
        <div className="flex gap-12 w-fit">
          <div className="w-[180px]">
            <Link to="/">
              <img src={Logo} width={"100%"} alt="" />
            </Link>
          </div>
          <div className="flex gap-8 text-button1">
            <Link to={"/faqs"} className="flex items-center">
              FAQs
            </Link>
            {/* <button className="flex items-center">{t("product")}</button> */}
            <Link to="/socialMedia" className="flex items-center">
              {t("media")}
            </Link>
            {/* <button className="flex items-center">{t("subscription")}</button> */}
          </div>
        </div>
        <div className="flex gap-4 ">
          {/* <button
            className="bg-[color:var(--theme-fifth)] text-button1 text-white px-6 py-2 rounded-[25px]"
            onClick={() => dispatch(authActions.setLoginModalClick(true))}
          >
            {t("login")}
          </button>
          <button
            className="bg-white text-[color:var(--theme-secondary)] text-button1 border border-[color:var(--theme-secondary)] px-6 py-2 rounded-[25px]"
            onClick={() => dispatch(authActions.setRegisterModalClick(true))}
          >
            {t("register")}
          </button>
          <button className="text-xl flex items-center">
            <BsCart3 />
          </button> */}
          <select
            value={currentLang}
            onChange={handleClickLang}
            className="p-2"
          >
            <option value={"en"} className="py-2">
              ENGLISH
            </option>
            <option value={"cn"}>简体中文</option>
            <option value={"tw"}>繁體中文</option>
          </select>
        </div>
      </div>
      {/* mobile mode*/}
      <div className="lg:hidden bg-[#ffffff] flex justify-between text-[color:var(--text-primary)] py-5 px-[10%] fixed top-0 w-full backdrop-[50px] z-50 ">
        <div className="flex">
          <div className="w-[120px]">
            <Link to="/">
              <img src={Logo} width={"100%"} alt="" />
            </Link>
          </div>
        </div>
        {/* <div className="flex gap-5">
          <button className="text-lg flex items-center">
            <BsCart3 />
          </button>
          <button className="text-2xl" onClick={handleClickDrop}>
            <HiOutlineMenu />
          </button>
        </div>
        <div
          className={
            mobileDrop
              ? `${dropDownStyle} left-[0px] ease-in-out duration-500`
              : `${dropDownStyle} left-[9999px] ease-in-out duration-500`
          }
        >
          <div className="flex flex-col gap-6 ">
            <div className="w-full flex justify-end pb-5">
              <button className="text-xl" onClick={handleClickDrop}>
                <RxCross1 />
              </button>
            </div>

            <button
              className="bg-[color:var(--theme-fifth)] text-button1 text-white px-3 py-2 rounded-lg"
              onClick={() => dispatch(authActions.setLoginModalClick(true))}
            >
              {t('login')}
            </button>
            <button
              className="bg-white text-[color:var(--theme-secondary)] text-button1 border border-[color:var(--theme-secondary)] px-3 py-2 rounded-lg"
              onClick={() => dispatch(authActions.setRegisterModalClick(true))}
            >
              {t('register')}
            </button>
          </div>
          <div className="mt-10 flex flex-col gap-8 text-button1">
            <button className="flex items-center">{t('product')}</button>
            <Link
              to="/socialMedia"
              className="flex items-center"
              onClick={handleClickDrop}
            >
              {t('media')}
            </Link>
            <button className="flex items-center">{t('subscription')}</button>
            <div className="flex gap-5">
              <button data-lang="en" onClick={handlerMobbileClickLang}>ENGLISH</button>
              <div className="border-r border-[color:var(--theme-secondary)]"/>
              <button data-lang="tw" onClick={handlerMobbileClickLang}>繁體中文</button>
              <div className="border-r border-[color:var(--theme-secondary)]"/>
              <button data-lang="cn" onClick={handlerMobbileClickLang}>简体中文</button>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};
export default Navigation;
