import React from "react";
import { useTranslation } from "react-i18next";

const SocialMedia = () => {
    const{t} = useTranslation()
    return (
        <div className="bg-gradientReverseImageBg grow py-[8%]">
            <div className="text-center flex flex-col items-center">
                <p className="text-headline1">{t('media-title')}</p>
                <p className="text-headline4 text-[color:var(--text-fourth)] w-5/12">{t('media-description')}</p>
            </div>
        </div>
    )
}

export default SocialMedia