const TW = {
    
  "product": "產品",
  "media": "社交媒體",
  "subscription": "訂閱服務",
  "register": "註冊",
  "login": "登入",
  "header-title": "克隆您的家人！",
  "header-content": "在我們的應用程式中展現AI藝術，用珍貴的回憶創作圖像和影片，搭配獨家框架，營造引人入勝的3D效果。",
  "feature-title": "時空奇觀：3D與AI融合",
  "feature-description": "我們將回憶無縫地與AI技術結合，為您帶來前所未有的創新體驗。",
  "feature-title-1": "NuWo 3D 相框",
  "feature-content-1": "通過3D框架，記憶栩栩如生，增添情感深度。",
  "feature-title-2": "AI圖像生成器",
  "feature-content-2": "利用AI魔法繪製想像，創作獨特的藝術品。",
  "feature-title-3": "AI影片合成器",
  "feature-content-3": "巧妙地融合元素和片段，創作出令人驚艷的影片作品。",
  "app-title": "探索應用程式",
  "app-content": "從框架定制到AI生成的奇蹟，通過視覺之旅探索我們應用程式的多樣功能。",
  "download": "下載Nuwo！",
  "ai-img-title": "AI智能圖像畫廊",
  "ai-img-content": "探索一系列捕捉不同主題的AI生成圖像。通過獨家框架提升體驗，賦予3D效果以生命。",
  "more": "瀏覽更多",
  "christmas": "聖誕節",
  "valentine": "情人節",
  "family": "家庭",
  "pets": "寵物",
  "faq": "常見問題",
  
  "faq-01": "NuWo 3D 相框開/關功能介紹",
  "faq-01-answer": "開關都需要長按電源開關鍵。",
  "faq-02": "NuWo 3D 相框連接設定",
  "faq-02-answer-1": "[NuWo 3D 相框] 點擊NuWo 3D 相框的設定圖標。",
  "faq-02-answer-2": "[NuWo 3D 相框] 在NuWo 3D 相框的設定頁面中點擊",
  "faq-02-answer-3": "[NuWo 3D 相框] 打開“使用Wi-Fi”切換。",
  "faq-02-answer-4": "[NuWo 3D 相框] 點擊選擇要連接的Wi-Fi網絡。 （圖片中的Wi-Fi網絡名稱可能根據用戶的地理位置和網絡環境而變化。）",
  "faq-02-answer-5": "[NuWo 3D 相框] 點擊“連接”建立連接。 （圖片中的Wi-Fi網絡名稱可能根據用戶的地理位置和網絡環境而變化。如果Wi-Fi連接不成功，請驗證Wi-Fi網絡的狀態。）",
  "faq-02-answer-6": "[NuWo 3D 相框] 成功連接到Wi-Fi。 （圖片中的Wi-Fi網絡名稱可能根據用戶的地理位置和網絡環境而變化。）",
  "faq-03": "綁定NuWo 3D 相框",
  "faq-03-answer-1": "[NuWo 3D 相框] 點擊NuWo 3D 相框的設定圖標。",
  "faq-03-answer-2": "[NuWo 3D 相框] 在NuWo 3D 相框的設定頁面中點擊“帳戶”。",
  "faq-03-answer-3": "[NuWo APP] 點擊NuWo APP右上角的圖標以添加圖標。",
  "faq-03-answer-4": "[NuWo APP] 掃描QR碼，將NuWo 3D 相框綁定到NuWo APP。",
  "faq-03-answer-5": "[NuWo APP] 在NuWo APP上點擊“綁定”按鈕。",
  "faq-03-answer-6": "[NuWo APP] 在NuWo APP上成功綁定。",
  "faq-04": "AI圖像生成器",
  "faq-04-answer-1": "[NuWo APP] 在NuWo APP上訪問AI 功能頁面。",
  "faq-04-answer-2": "[NuWo APP] 在NuWo APP上點擊AI圖像生成器。",
  "faq-04-answer-3": "[NuWo APP] 在NuWo APP上輸入所需圖像生成的關鍵字。",
  "faq-04-answer-4": "[NuWo APP] 您可以點擊鍵盤上的搜索按鈕，或者在折疊鍵盤時，會在頂部出現一個“搜索”按鈕。在NuWo APP上點擊它。",
  "faq-04-answer-5": "[NuWo APP] 如果您想下載AI生成的圖像，請在NuWo APP右上角點擊“選擇”按鈕。",
  "faq-04-answer-6": "[NuWo APP] 在NuWo APP上選擇要下載的AI圖像。",
  "faq-04-answer-7": "[NuWo APP] 在NuWo APP上點擊下面的“下載”按鈕。",
  "faq-04-answer-8": "[NuWo APP] 選擇存儲文件夾（界面可能會有所不同）。",
  "faq-04-answer-9": "[NuWo APP] 在NuWo APP上點擊“使用此文件夾”按鈕。",
  "faq-04-answer-10": "[NuWo APP] 授予訪問權限，並進行下載操作。（如果",
  "faq-04-answer-11": "在NuWo APP上成功下載。",
  "faq-05": "AI影片合成器",
  "faq-05-answer-1": "[NuWo APP] 在NuWo APP上訪問AI 功能頁面。",
  "faq-05-answer-2": "[NuWo APP] 在NuWo APP上點擊AI影片合成器。",
  "faq-05-answer-3": "[NuWo APP] 從移動設備上傳一張正面肖像圖像到NuWo APP。 （界面可能會根據每個手機而有所不同。）",
  "faq-05-answer-4": "[NuWo APP] 在NuWo APP上編輯圖像。 （界面可能會根據每個手機而有所不同。）",
  "faq-05-answer-5": "[NuWo APP] 在NuWo APP上輸入少量文本。 （建議在10個字以內）",
  "faq-05-answer-6": "[NuWo APP] 在NuWo APP上點擊“確認”按鈕。",
  "faq-05-answer-7": "[NuWo APP] 選擇存儲文件夾並保存。 （界面可能會根據每個手機而有所不同。）",
  "faq-05-answer-8": "[NuWo APP] 在NuWo APP上進行視頻生成。 *請稍等片刻，然後再檢查您之前選擇的指定文件夾。如果視頻製作過程需要較長時間，請確保您當前的網絡連接穩定。",
  "faq-05-answer-9": "[NuWo APP] 請稍等幾分鐘後，請檢查指定的文件夾是否有視頻",
  "faq-06": "上傳到NuWo 3D 相框",
  "faq-06-answer-1": "[NuWo APP] 在NuWo APP上點擊下方的上傳圖標按鈕。",
  "faq-06-answer-2": "[NuWo APP] 在NuWo APP上選擇所需的上傳方法。（當然，以“本地文件”為例）",
  "faq-06-answer-3": "[NuWo APP] 選擇要上傳到NuWo 3D 相框的圖像。 （界面可能會根據每個手機而有所不同。）",
  "faq-06-answer-4": "[NuWo APP] 編輯圖像。 （界面可能會根據每個手機而有所不同。）",
  "faq-06-answer-5": "[NuWo APP] 在NuWo APP上選擇要上傳的框架。",
  "faq-06-answer-6": "如果在上傳過程中，您可以在NuWo APP上的“任務中心”中檢查上傳進度。 *如果上傳未在較長時間內完成，請確保您的互聯網連接穩定且正常運行。",
  "faq-06-answer-7": "[NuWo APP] 上傳後，您可以在NuWo APP上的“播放列表”中查看當前播放列表。",
  "faq-06-answer-8": "[NuWo 3D 相框]NuWo 3D 相框的播放列表也將同步更新。",
  "step": "步驟.",
  "media-title": "AI創造多元主題",
  "media-description": "在假期、家庭時刻等方面，通過AI藝術之旅探索多種主題。在每張圖像中擁抱多樣的主題。",
  "cart-status-01": "確認產品信息",
  "cart-status-02": "輸入運送信息和付款",
  "cart-status-03": "購買完成",
  "item": "品項",
  "price": "價格",
  "quantity": "數量",
  "total": "總計",
  "next": "下一步",
  "orderer": "訂購人信息",
  "pecipient": "收件人信息",
  "name": "姓名",
  "email": "電子郵件",
  "country": "聯絡電話號碼",
  "contact": "國家",
  "address": "地址",
  "tw": "台灣",
  "us": "美國",
  "cn": "中國",
  "address_hint": "如果無人接收商品，將退回至此地址。",
  "payment": "付款信息",
  "total-amount": "總金額",
  "card": "信用卡號碼",
  "card-exp": "信用卡過期日期",
  "cvc": "CVC碼（卡片後三位數字）",
  "cardholder-name": "持卡人姓名",
  "us-dollars": "美元",
  "order_completed": "訂單完成",
  "product-title": "釋放想像，擁抱現實：發現NuWo AI的 NuWo 3D 相框，帶來數字人類感受",
  "download-ios": "iOS應用程式",
  "download-android": "Android應用程式",
  "buy-gallery": "購買3D相框",
  "operating-manual": "操作手冊",
  "app-feature": "NuWo APP功能",
  "ai-image-title": "AI創意無限，從關鍵字生成多樣圖像。",
  "ai-image-content": "通過我們強大的AI圖像生成功能，將您的想像力轉化為視覺現實。只需輸入感興趣的關鍵字，智能系統將根據這些關鍵字生成多種驚人圖像。您可以自由下載這些AI生成的圖像，用於設計、創意靈感，甚至個人收藏。更令人興奮的是，您可以輕鬆將這些圖像上傳至我們的專屬3D框架，為每個創作提供立體展示。",
  "ai-video-title": "AI影片合成：照片與聲音的奇蹟融合",
  "ai-video-content": "我們引以為傲的AI影片合成功能為您帶來前所未有的身臨其境體驗。只需上傳一張正面人像圖片，輸入文字內容，選擇相關音頻，讓我們的AI在幕後施展其魔法。根據您提供的圖像、文字和音頻，我們的AI創建動態影片，利用選擇的聲音清晰地表達文字內容。無論是個人問候、特殊祝福，或獨特的創意宣傳，此功能將以最直觀的方式將您的想法和情感傳遞給觀眾。",
  "operating-title": "主宰藝術空間：自由操控3D相框",
  "operating-content": "我們為您帶來無與倫比的3D相框操控能力，不僅是一個藝術展示空間，更是您個人的創意舞台。\n將珍藏的照片、藝術品和創作上傳至我們專屬的3D相框，輕鬆打造自己的畫廊。透過簡單的控制，您可以自由決定何時以及如何展示您的作品。無論是展示一系列照片，還是在不同間隔時間內安排單個藝術作品的幻燈片展示，此功能將把您的創意放在您的手中。",
  "gallery-appearance": "NuWo 3D 相框外觀",
  "gallery-spec": "NuWo 3D 相框規格"

};
export default TW;
