const EN = {
  product: "Product",
  media: "Social media",
  subscription: "Subscription",
  register: "Register",
  login: "Sign in",
  "header-title": "Clone your family!",
  "header-content":
    "Unleash AI art in our app, crafting images and videos with cherished memories, enhanced by exclusive frames for captivating 3D effects.",
  "feature-title": "Temporal Marvels: 3D and AI Fusion",
  "feature-description":
    "We seamlessly blend memories with AI technology, bringing you an unparalleled innovative experience.",
  "feature-title-1": "3D Graphic gallery",
  "feature-content-1":
    "Infusing 3D frames, memories come to life vividly, adding emotional depth.",
  "feature-title-2": "AI Image Generator",
  "feature-content-2":
    "Harnessing AI magic to paint imagination, crafting unique art.",
  "feature-title-3": "AI Video Synthesizer",
  "feature-content-3":
    "Skillfully blending elements and segments to create stunning cinematic pieces.",
  "app-title": "Exploring the App",
  "app-content":
    "Navigate through a visual journey showcasing our app’s diverse functionalities, from frame customization to AI-generated wonders.",
  download: "Download Nuwo!",
  "ai-img-title": "AI Smart Image Gallery",
  "ai-img-content":
    "Explore an array of AI-generated images capturing diverse themes. Elevate the experience with exclusive frames that bring 3D effects to life.",
  more: "Browse more",
  christmas: "Christmas",
  valentine: "Valentine’s Day",
  family: "Family",
  pets: "Pets",

  faq: "FAQs",
  "faq-01": "3D graphic gallery power On/Off Introduction",
  "faq-01-answer":
    "Both turning on and off require a long press of the power switch key.",
  "faq-02": "3D graphic gallery connection settings",
  "faq-02-answer-1":
    "[NuWo Graphic Gallery] Click on the NuWo Graphic Gallery's settings icon.",
  "faq-02-answer-2": `[NuWo Graphic Gallery] Click on the "Network" in the NuWo Graphic\nGallery's settings page.`,
  "faq-02-answer-3": "[NuWo Graphic Gallery] Turn on the 'Use Wi-Fi' toggle",
  "faq-02-answer-4":
    "[NuWo Graphic Gallery] Tap to select the desired Wi-Fi network to connect to. (The Wi-Fi network names in the images may vary based on the user's geographical location and network environment.)",
  "faq-02-answer-5": `[NuWo Graphic Gallery] Tap "CONNECT" to establish the connection. (The Wi-Fi network names in the images may vary based on the user's geographical location and network environment. If the Wi-Fi connection is unsuccessful, please verify the status of the Wi-Fi network.)`,
  "faq-02-answer-6":
    "[NuWo Graphic Gallery] Successfully connected to Wi-Fi. (The Wi-Fi network names in the images may vary based on the user's geographical location and network environment.)",
  "faq-03": "Bind NuWo graphic gallery",
  "faq-03-answer-1":
    "[NuWo Graphic Gallery] Click on the NuWo Graphic Gallery's settings icon. ",
  "faq-03-answer-2": `[NuWo Graphic Gallery] "Click on the "Account" in the NuWo Graphic Gallery's settings page.`,
  "faq-03-answer-3":
    "[NuWo APP] Click on the icon at the upper right corner to the add icon on the NuWo app. ",
  "faq-03-answer-4":
    "[NuWo APP] Scan the QR code for binding the NuWo Graphic Gallery on the NuWo app. ",
  "faq-03-answer-5": `[NuWo APP] Click the "Bind" button on the NuWo app.`,
  "faq-03-answer-6": "[NuWo APP] Binding successfully on the NuWo app.",
  "faq-04": "AI Image Generator",
  "faq-04-answer-1": "[NuWo APP] Access the AI Mapping page on the NuWo app.",
  "faq-04-answer-2": "[NuWo APP] Click on AI Image Generator on the NuWo app.",
  "faq-04-answer-3":
    "[NuWo APP] Enter the keywords for the desired image generation on the NuWo app.",
  "faq-04-answer-4":
    "[NuWo APP] You can tap the search button on the keyboard or, when you collapse the keyboard, a 'Search' button will appear at the top. Click it on the NuWo APP.",
  "faq-04-answer-5": `[NuWo APP] If you wish to download AI-generated images, please click on the "Choose" button in the upper right corner on the NuWo app.`,
  "faq-04-answer-6":
    "[NuWo APP] Select the AI image you want to download on the NuWo app.",
  "faq-04-answer-7": `[NuWo APP] Click on the "Download" button below on the NuWo app.`,
  "faq-04-answer-8":
    "[NuWo APP] Choose the folder for storag(The interface may vary depending on each phone.)",
  "faq-04-answer-9": "[NuWo APP] Click on the ",
  "faq-04-answer-10":
    "[NuWo APP] Grant access permission and download action will take place.(If you have previously granted this permission, there's a chance that this prompt may not appear.)",
  "faq-04-answer-11": "Download successfully on the NuWo app.",
  "faq-05": "AI Video Synthesizer",
  "faq-05-answer-1": "[NuWo APP] Access the AI Mapping page on the NuWo app.",
  "faq-05-answer-2":
    "[NuWo APP] Click on AI Video Synthesizer on the NuWo app.",
  "faq-05-answer-3":
    "[NuWo APP] Upload a frontal portrait image from your mobile device on the NuWo app.(The interface may vary depending on each phone.)",
  "faq-05-answer-4":
    "[NuWo APP] Edit the image on the NuWo app.(The interface may vary depending on each phone.",
  "faq-05-answer-5":
    "[NuWo APP] Enter a short amount of text on the NuWo app.(recommended within 10 words)",
  "faq-05-answer-6": `[NuWo APP] Click the "Confirm" button on the NuWo app.`,
  "faq-05-answer-7":
    "[NuWo APP] Choose the folder for storage and save(The interface may vary depending on each phone.)",
  "faq-05-answer-8":
    "[NuWo APP] Video generation in progress on the NuWo app. *Please wait a few minutes before checking the designated folder you chose earlier. If the video production process takes an extended period, kindly ensure the stability of your current network connection.",
  "faq-05-answer-9":
    "[NuWo APP] Please check the designated folder for the video after a few minutes.",
  "faq-06": "Upload to NuWo Graphic Gallery",
  "faq-06-answer-1":
    "[NuWo APP] Click the upload icon button below on the NuWo app.",
  "faq-06-answer-2":
    "[NuWo APP] Choose the desired method of upload on the NuWo app.(Certainly, using ",
  "faq-06-answer-3":
    "[NuWo APP] Select the image you want to upload to the NuWo Graphic Gallery.(The interface may vary depending on each phone.)",
  "faq-06-answer-4":
    "[NuWo APP] Edit the image.(The interface may vary depending on each phone.)",
  "faq-06-answer-5":
    "[NuWo APP] Choose the frame you want to upload on the NuWo app.",
  "faq-06-answer-6": `
  If in the process of uploading, you can check the upload progress in the "Task center." on the NuWo app. *If uploading is not completed for an extended period, please ensure that your internet connection is stable and functioning.`,
  "faq-06-answer-7":
    `[NuWo APP] After uploading, you can view the current playlist in "Playlist" on the NuWo app.`,
  "faq-06-answer-8":
    "[NuWo Graphic Gallery] The playlist for the NuWo Graphic Gallery will also be updated simultaneously.",
  step: "Step.",
  "media-title": "AI creates diverse themes explored",
  "media-description":
    "Journey through AI art across holidays, family moments, and more. Embrace diverse themes in every image.",
  "cart-status-01": "Confirm product information",
  "cart-status-02": "Enter shipping information and payment",
  "cart-status-03": "Purchase completed",
  item: "Item",
  price: "Price",
  quantity: "Quantity",
  total: "Total",
  next: "Next step",
  orderer: "Orderer information",
  pecipient: "Recipient information",
  name: "Name",
  email: "Email",
  country: "Contact phone number",
  contact: "Country",
  address: "Address",
  address_hint:
    "If no one is available to receive the goods, they will be returned to this address.",
  payment: "Payment information",
  "total-amount": "Total amount",
  card: "Credit card number",
  "card-exp": "Credit card expiration date",
  cvc: "CVC code (last three digits on the card)",
  "cardholder-name": "Cardholder name",
  "us-dollars": "US dollars",
  order_completed: "Order completed",
  "product-title": `Unleash Imagination, Embrace \nReality: Discover NuWo AI's 3D
Graphic Gallery with Digital 
Human Sensation`,
  "download-ios": "iOS APP",
  "download-android": "Android APP",
  "buy-gallery": "Buy 3D Gallery",
  "operating-manual": "Operating Manual",
  "app-feature": "NuWo App Functions",
  "ai-image-title":
    "AI creativity knows no bounds, generating diverse images from keywords.",
  "ai-image-content":
    "Through our powerful AI image generation feature, we transform your imagination into visual reality. Simply input keywords of interest, and our intelligent system will generate a variety of stunning images based on these keywords.You have the freedom to download these AI-generated images for use in design, creative inspiration, or even personal collections. What's even more exciting is that you can easily upload these images to our exclusive 3D frames, providing each creation with a vivid display in a three-dimensional space.",
  "ai-video-title":
    "AI Video Synthesis: The Miraculous Blend of Photos and Sound",
  "ai-video-content":
    "Our pride-worthy AI video synthesis feature brings an unprecedented immersive experience to you. Just upload a frontal portrait image, input text content, select accompanying audio, and let our AI work its magic behind the scenes.From the image, text, and audio you provide, our AI creates dynamic videos that articulate the text content using the selected voice. Whether it's a personal greeting, special wishes, or a unique creative promotion, this feature will convey your ideas and emotions to your audience in the most intuitive manner.",
  "operating-title": "Master the Artistic Space: Free Control of 3D Frames",
  "operating-content":
    "We bring you the unparalleled capability to control 3D frames, making it not just an artistic display space, but your personal creative stage.\nUpload your cherished photos, artworks, and creations to our exclusive 3D frames and effortlessly craft your own gallery. Through simple controls, you can freely decide when and how to showcase your creations. Whether it's displaying a series of photos or scheduling a slideshow of individual artworks at different intervals, this feature puts your creativity in your hands.",
  "gallery-appearance": "3D Graphic Gallery Appearance",
  "gallery-spec": "3D Graphic Gallery Specifications",
};
export default EN;
