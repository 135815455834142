const CN = {
  "product": "产品",
  "media": "社交媒体",
  "subscription": "订阅服务",
  "register": "注册",
  "login": "登入",
  "header-title": "克隆您的家人！",
  "header-content": "在我们的应用程式中展现AI艺术，用珍贵的回忆创作图像和影片，搭配独家框架，营造引人入胜的3D效果。",
  "feature-title": "时空奇观：3D与AI融合",
  "feature-description": "我们将回忆无缝地与AI技术结合，为您带来前所未有的创新体验。",
  "feature-title-1": "NuWo 3D 相框",
  "feature-content-1": "通过3D框架，记忆栩栩如生，增添情感深度。",
  "feature-title-2": "AI图像生成器",
  "feature-content-2": "利用AI魔法绘制想像，创作独特的艺术品。",
  "feature-title-3": "AI影片合成器",
  "feature-content-3": "巧妙地融合元素和片段，创作出令人惊艳的影片作品。",
  "app-title": "探索应用程式",
  "app-content": "从框架定制到AI生成的奇迹，通过视觉之旅探索我们应用程式的多样功能。",
  "download": "下载Nuwo！",
  "ai-img-title": "AI智能图像画廊",
  "ai-img-content": "探索一系列捕捉不同主题的AI生成图像。通过独家框架提升体验，赋予3D效果以生命。",
  "more": "浏览更多",
  "christmas": "圣诞节",
  "valentine": "情人节",
  "family": "家庭",
  "pets": "宠物",
  "faq": "常见问题",
  
  "faq-01": "NuWo 3D 相框开/关功能介绍",
  "faq-01-answer": "开关都需要长按电源开关键。",
  "faq-02": "NuWo 3D 相框连接设定",
  "faq-02-answer-1": "[NuWo 3D 相框] 点击NuWo 3D 相框的设定图标。",
  "faq-02-answer-2": "[NuWo 3D 相框] 在NuWo 3D 相框的设定页面中点击",
  "faq-02-answer-3": "[NuWo 3D 相框] 打开“使用Wi-Fi”切换。",
  "faq-02-answer-4": "[NuWo 3D 相框] 点击选择要连接的Wi-Fi网络。 （图片中的Wi-Fi网络名称可能根据用户的地理位置和网络环境而变化。）",
  "faq-02-answer-5": "[NuWo 3D 相框] 点击“连接”建立连接。 （图片中的Wi-Fi网络名称可能根据用户的地理位置和网络环境而变化。如果Wi-Fi连接不成功，请验证Wi-Fi网络的状态。）",
  "faq-02-answer-6": "[NuWo 3D 相框] 成功连接到Wi-Fi。 （图片中的Wi-Fi网络名称可能根据用户的地理位置和网络环境而变化。）",
  "faq-03": "绑定NuWo 3D 相框",
  "faq-03-answer-1": "[NuWo 3D 相框] 点击NuWo 3D 相框的设定图标。",
  "faq-03-answer-2": "[NuWo 3D 相框] 在NuWo 3D 相框的设定页面中点击“帐户”。",
  "faq-03-answer-3": "[NuWo APP] 点击NuWo APP右上角的图标以添加图标。",
  "faq-03-answer-4": "[NuWo APP] 扫描QR码，将NuWo 3D 相框绑定到NuWo APP。",
  "faq-03-answer-5": "[NuWo APP] 在NuWo APP上点击“绑定”按钮。",
  "faq-03-answer-6": "[NuWo APP] 在NuWo APP上成功绑定。",
  "faq-04": "AI图像生成器",
  "faq-04-answer-1": "[NuWo APP] 在NuWo APP上访问AI 功能页面。",
  "faq-04-answer-2": "[NuWo APP] 在NuWo APP上点击AI图像生成器。",
  "faq-04-answer-3": "[NuWo APP] 在NuWo APP上输入所需图像生成的关键字。",
  "faq-04-answer-4": "[NuWo APP] 您可以点击键盘上的搜索按钮，或者在折叠键盘时，会在顶部出现一个“搜索”按钮。在NuWo APP上点击它。",
  "faq-04-answer-5": "[NuWo APP] 如果您想下载AI生成的图像，请在NuWo APP右上角点击“选择”按钮。",
  "faq-04-answer-6": "[NuWo APP] 在NuWo APP上选择要下载的AI图像。",
  "faq-04-answer-7": "[NuWo APP] 在NuWo APP上点击下面的“下载”按钮。",
  "faq-04-answer-8": "[NuWo APP] 选择存储文件夹（界面可能会有所不同）。",
  "faq-04-answer-9": "[NuWo APP] 在NuWo APP上点击“使用此文件夹”按钮。",
  "faq-04-answer-10": "[NuWo APP] 授予访问权限，并进行下载操作。 （如果",
  "faq-04-answer-11": "在NuWo APP上成功下载。",
  "faq-05": "AI影片合成器",
  "faq-05-answer-1": "[NuWo APP] 在NuWo APP上访问AI 功能页面。",
  "faq-05-answer-2": "[NuWo APP] 在NuWo APP上点击AI影片合成器。",
  "faq-05-answer-3": "[NuWo APP] 从移动设备上传一张正面肖像图像到NuWo APP。 （界面可能会根据每个手机而有所不同。）",
  "faq-05-answer-4": "[NuWo APP] 在NuWo APP上编辑图像。 （界面可能会根据每个手机而有所不同。）",
  "faq-05-answer-5": "[NuWo APP] 在NuWo APP上输入少量文本。 （建议在10个字以内）",
  "faq-05-answer-6": "[NuWo APP] 在NuWo APP上点击“确认”按钮。",
  "faq-05-answer-7": "[NuWo APP] 选择存储文件夹并保存。 （界面可能会根据每个手机而有所不同。）",
  "faq-05-answer-8": "[NuWo APP] 在NuWo APP上进行视频生成。 *请稍等片刻，然后再检查您之前选择的指定文件夹。如果视频制作过程需要较长时间，请确保您当前的网络连接稳定。",
  "faq-05-answer-9": "[NuWo APP] 请稍等几分钟后，请检查指定的文件夹是否有视频",
  "faq-06": "上传到NuWo 3D 相框",
  "faq-06-answer-1": "[NuWo APP] 在NuWo APP上点击下方的上传图标按钮。",
  "faq-06-answer-2": "[NuWo APP] 在NuWo APP上选择所需的上传方法。 （当然，以“本地文件”为例）",
  "faq-06-answer-3": "[NuWo APP] 选择要上传到NuWo 3D 相框的图像。 （界面可能会根据每个手机而有所不同。）",
  "faq-06-answer-4": "[NuWo APP] 编辑图像。 （界面可能会根据每个手机而有所不同。）",
  "faq-06-answer-5": "[NuWo APP] 在NuWo APP上选择要上传的框架。",
  "faq-06-answer-6": "如果在上传过程中，您可以在NuWo APP上的“任务中心”中检查上传进度。 *如果上传未在较长时间内完成，请确保您的互联网连接稳定且正常运行。",
  "faq-06-answer-7": "[NuWo APP] 上传后，您可以在NuWo APP上的“播放列表”中查看当前播放列表。",
  "faq-06-answer-8": " [NuWo 3D 相框]NuWo 3D 相框的播放列表也将同步更新。",
  "step": "步骤.",
  "media-title": "AI创造多元主题",
  "media-description": "在假期、家庭时刻等方面，通过AI艺术之旅探索多种主题。在每张图像中拥抱多样的主题。",
  "cart-status-01": "确认产品信息",
  "cart-status-02": "输入运送信息和付款",
  "cart-status-03": "购买完成",
  "item": "品项",
  "price": "价格",
  "quantity": "数量",
  "total": "总计",
  "next": "下一步",
  "orderer": "订购人信息",
  "pecipient": "收件人信息",
  "name": "姓名",
  "email": "电子邮件",
  "country": "联络电话号码",
  "contact": "国家",
  "address": "地址",
  "tw": "台湾",
  "us": "美国",
  "cn": "中国",
  "address_hint": "如果无人接收商品，将退回至此地址。",
  "payment": "付款信息",
  "total-amount": "总金额",
  "card": "信用卡号码",
  "card-exp": "信用卡过期日期",
  "cvc": "CVC码（卡片后三位数字）",
  "cardholder-name": "持卡人姓名",
  "us-dollars": "美元",
  "order_completed": "订单完成",
  "product-title": "释放想像，拥抱现实：发现NuWo AI的 NuWo 3D 相框，带来数字人类感受",
  "download-ios": "iOS应用程式",
  "download-android": "Android应用程式",
  "buy-gallery": "购买3D 相框",
  "operating-manual": "操作手册",
  "app-feature": "NuWo APP功能",
  "ai-image-title": "AI创意无限，从关键字生成多样图像。",
  "ai-image-content": "通过我们强大的AI图像生成功能，将您的想像力转化为视觉现实。只需输入感兴趣的关键字，智能系统将根据这些关键字生成多种惊人图像。您可以自由下载这些AI生成的图像，用于设计、创意灵感，甚至个人收藏。更令人兴奋的是，您可以轻松将这些图像上传至我们的专属3D框架，为每个创作提供立体展示。",
  "ai-video-title": "AI影片合成：照片与声音的奇迹融合",
  "ai-video-content": "我们引以为傲的AI影片合成功能为您带来前所未有的身临其境体验。只需上传一张正面人像图片，输入文字内容，选择相关音频，让我们的AI在幕后施展其魔法。根据您提供的图像、文字和音频，我们的AI创建动态影片，利用选择的声音清晰地表达文字内容。无论是个人问候、特殊祝福，或独特的创意宣传，此功能将以最直观的方式将您的想法和情感传递给观众。",
  "operating-title": "主宰艺术空间：自由操控3D相框",
  "operating-content": "我们为您带来无与伦比的3D相框操控能力，不仅是一个艺术展示空间，更是您个人的创意舞台。将珍藏的照片、艺术品和创作上传至我们专属的3D相框，轻松打造自己的画廊。透过简单的控制，您可以自由决定何时以及如何展示您的作品。无论是展示一系列照片，还是在不同间隔时间内安排单个艺术作品的幻灯片展示，此功能将把您的创意放在您的手中。 ",
  "gallery-appearance": "NuWo 3D 相框外观",
  "gallery-spec": "NuWo 3D 相框规格"
};
export default CN;
