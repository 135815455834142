import React,{useEffect} from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import TemplateFront from "./front/TemplateFront";
import Home from "./front/screens/home/Home";
import Faqs from './front/screens/faqs/Faqs'
import SocialMedia from "./front/screens/socialmedia/SocialMedia";

function App() {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0,0)
  },[location])
  return (
      <Routes>
        <Route path="/" element={<TemplateFront />}>
          <Route index element={<Home />} />
          <Route path="faqs" element={<Faqs />} />
          <Route path="socialMedia" element={<SocialMedia />} />
        </Route>
      </Routes>
  );
}

export default App;
