import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import Machine from '../../../image/machine.png'

//style
const liStyle = `[&>*:first-child]:whitespace-nowrap [&>*:first-child]:font-bold text-title1 text-[color:var(--text-third)] flex gap-3`;
const Faqs = () => {
  const { t } = useTranslation();
  const [currentFaq, setCurrentFaq] = useState<string[]>([""]);
  const handleClickFaq = (e: React.MouseEvent<HTMLButtonElement>) => {
    const value = e.currentTarget.dataset.set || "";
    if (currentFaq.includes(value)) {
      const newData = currentFaq.filter((d) => {
        return d !== value;
      });
      setCurrentFaq(newData);
    } else {
      const newData = [...currentFaq, value];
      setCurrentFaq(newData);
    }
  };
  return (
    <div className="bg-gradientReverseImageBg bg-no-repeat grow py-[10%] sm:py-[5%] flex flex-col items-center">
      <div className="text-center flex flex-col items-center">
        <p className="text-headline1">{t('faq')}</p>
        <p className="text-title1 sm:text-headline4 text-[color:var(--text-fourth)]">
          Have questions? We’re here to help.
        </p>
      </div>
      <div className="w-11/12 sm:w-1/2 mt-[5%]">
        <Faq1 currentFaq={currentFaq} handleClickFaq={handleClickFaq} t={t} />
        <Faq2 currentFaq={currentFaq} handleClickFaq={handleClickFaq} t={t} />
        <Faq3 currentFaq={currentFaq} handleClickFaq={handleClickFaq} t={t} />
        <Faq4 currentFaq={currentFaq} handleClickFaq={handleClickFaq} t={t} />
        <Faq5 currentFaq={currentFaq} handleClickFaq={handleClickFaq} t={t} />
        <Faq6 currentFaq={currentFaq} handleClickFaq={handleClickFaq} t={t} />
      </div>
    </div>
  );
};

export default Faqs;

interface faqProps {
  currentFaq: string[];
  handleClickFaq: (e: React.MouseEvent<HTMLButtonElement>) => void;
  t: Function;
}
const Faq1 = (props: faqProps) => {
  return (
    <div className="border-b border-[color:var(--theme-sixth)] text-[color:var(--text-fourth)] text-title1 sm:text-headline3">
      <button
        className="flex justify-between items-center w-full py-3"
        data-set="faq1"
        onClick={props.handleClickFaq}
      >
        <p className="text-start">{props.t("faq-01")}</p>
        {props.currentFaq.includes("faq1") ? (
          <IoIosArrowUp />
        ) : (
          <IoIosArrowDown />
        )}
      </button>
      <div className={props.currentFaq.includes("faq1") ? "p-8" : "hidden"}>
        <p className="text-title1 text-[color:var(--text-third)] mb-6">
          {props.t("faq-01-answer")}
        </p>
        <img src={Machine} alt="" width={'60%'}/>
      </div>
    </div>
  );
};
const Faq2 = (props: faqProps) => {
  const faq1ArrAns = ["1", "2", "3", "4", "5", "6"];
  return (
    <div className="border-b border-[color:var(--theme-sixth)] text-[color:var(--text-fourth)] text-title1 sm:text-headline3">
      <button
        className="flex justify-between items-center w-full py-3"
        data-set="faq2"
        onClick={props.handleClickFaq}
      >
        <p>{props.t("faq-02")}</p>
        {props.currentFaq.includes("faq2") ? (
          <IoIosArrowUp />
        ) : (
          <IoIosArrowDown />
        )}
      </button>
      <div className={props.currentFaq.includes("faq2") ? "p-8" : "hidden"}>
        <ul>
          {faq1ArrAns.map((d, i) => {
            return (
              <li className={liStyle} key={i}>
                <p>
                  {props.t("step")}
                  {d}
                </p>
                <p>{props.t(`faq-02-answer-${d}`)}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
const Faq3 = (props: faqProps) => {
  const faq3ArrAns = ["1", "2", "3", "4", "5", "6"];
  return (
    <div className="border-b border-[color:var(--theme-sixth)] text-[color:var(--text-fourth)] text-title1 sm:text-headline3">
      <button
        className="flex justify-between items-center w-full py-3"
        data-set="faq3"
        onClick={props.handleClickFaq}
      >
        <p>{props.t("faq-03")}</p>
        {props.currentFaq.includes("faq3") ? (
          <IoIosArrowUp />
        ) : (
          <IoIosArrowDown />
        )}
      </button>
      <div className={props.currentFaq.includes("faq3") ? "p-8" : "hidden"}>
        <ul>
          {faq3ArrAns.map((d, i) => {
            return (
              <li className={liStyle} key={i}>
                <p>{props.t("step")}{d}</p>
                <p>{props.t(`faq-03-answer-${d}`)}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
const Faq4 = (props: faqProps) => {
  const faq4ArrAns = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
  return (
    <div className="border-b border-[color:var(--theme-sixth)] text-[color:var(--text-fourth)] text-title1 sm:text-headline3">
      <button
        className="flex justify-between items-center w-full py-3"
        data-set="faq4"
        onClick={props.handleClickFaq}
      >
        <p>{props.t("faq-04")}</p>
        {props.currentFaq.includes("faq4") ? (
          <IoIosArrowUp />
        ) : (
          <IoIosArrowDown />
        )}
      </button>
      <div className={props.currentFaq.includes("faq4") ? "p-8" : "hidden"}>
        <ul>
          {faq4ArrAns.map((d, i) => {
            return (
              <li className={liStyle} key={i}>
                <p>{props.t("step")}{d}</p>
                <p>{props.t(`faq-04-answer-${d}`)}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
const Faq5 = (props: faqProps) => {
  const faq4ArrAns = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];
  return (
    <div className="border-b border-[color:var(--theme-sixth)] text-[color:var(--text-fourth)] text-title1 sm:text-headline3">
      <button
        className="flex justify-between items-center w-full py-3"
        data-set="faq5"
        onClick={props.handleClickFaq}
      >
        <p>{props.t("faq-05")}</p>
        {props.currentFaq.includes("faq5") ? (
          <IoIosArrowUp />
        ) : (
          <IoIosArrowDown />
        )}
      </button>
      <div className={props.currentFaq.includes("faq5") ? "p-8" : "hidden"}>
        <ul>
          {faq4ArrAns.map((d, i) => {
            return (
              <li className={liStyle} key={i}>
                <p>{props.t("step")}{d}</p>
                <p>{props.t(`faq-05-answer-${d}`)}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
const Faq6 = (props: faqProps) => {
  const faq4ArrAns = ["1", "2", "3", "4", "5", "6", "7", "8"];
  return (
    <div className="border-b border-[color:var(--theme-sixth)] text-[color:var(--text-fourth)] text-title1 sm:text-headline3">
      <button
        className="flex justify-between items-center w-full py-3"
        data-set="faq6"
        onClick={props.handleClickFaq}
      >
        <p>{props.t("faq-06")}</p>
        {props.currentFaq.includes("faq6") ? (
          <IoIosArrowUp />
        ) : (
          <IoIosArrowDown />
        )}
      </button>
      <div className={props.currentFaq.includes("faq6") ? "p-8" : "hidden"}>
        <ul>
          {faq4ArrAns.map((d, i) => {
            return (
              <li className={liStyle} key={i}>
                <p>{props.t("step")}{d}</p>
                <p>{props.t(`faq-06-answer-${d}`)}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
