import React, { ReactNode, useState } from "react";
import Carousel from "nuka-carousel";
import { useAppDispatch } from "../../../../store/hooks";
import { GrNext, GrPrevious } from "react-icons/gr";

interface NuCarouselProps {
  components: ReactNode[];
  autoReplay: boolean;
  className?: string;
  showNum: number;
}

const NuCarousel = (props: NuCarouselProps) => {

  return (
    <Carousel
      className={props.className}
      autoplay={false}
      cellAlign={'center'}
      animation={'zoom'}
      slidesToScroll={1}
      wrapAround={true}
      renderBottomCenterControls={() => <div></div>}
      slidesToShow={props.showNum}
      renderCenterLeftControls={({ nextDisabled, nextSlide }) => (
        <button onClick={nextSlide} className={`${props.className}`}>
          <GrPrevious className="text-[1.125rem] sm:text-3xl" />
        </button>
      )}
      renderCenterRightControls={({  previousDisabled, previousSlide }) => (
        <button onClick={previousSlide} className={props.className}>
          <GrNext className="text-[1.125rem] sm:text-3xl" />
        </button>
      )}
    >
      {props.components.map((component) => (
        <div key={Math.random()}>{component}</div>
      ))}
    </Carousel>
  );
};

export default NuCarousel;
