import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/auth.slice";
import langsSlice from "./langs/langs.slice";

const store = configureStore({
    reducer:{
        auth:authSlice.reducer,
        langs:langsSlice.reducer,
    },
    devTools:process.env.NODE_ENV !== "production"
})

export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch