import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { authActions } from "../../../../store/auth/auth.slice";
import { Link } from "react-router-dom";
import Logo from '../../../../image/logo.png'
const RegisterPage = () => {
  const { registerModalClick } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const handleClickLogin = () => {
    dispatch(authActions.setLoginModalClick(true));
    dispatch(authActions.setRegisterModalClick(false));
  };
  const handleRegisterSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };
  return (
    <div
      className={
        registerModalClick
          ? "transition duration-700 flex w-full bg-[#f8f8f8]"
          : "opacity-0 z-[-100] absolute"
      }
    >
      <div className="hidden lg:flex flex-col w-1/2 bg-[color:var(--theme-thrid)] border-r border-slate-300 p-10 ">
        <p className="text-title1">Welcome</p>
        <div className="grow flex justify-center items-center">
        <img src={Logo} width={'70%'} alt="" />
        </div>
        <button className="text-title2 w-fit" onClick={handleClickLogin}>
          Login
        </button>
      </div>
      <form
        onSubmit={handleRegisterSubmit}
        className="w-full lg:w-1/2 p-10 flex flex-col gap-5"
      >
        <p className="text-title1">Register with your e-mail!</p>
        <label>
          <p className="text-[color:var(--text-secondary)] text-body3">
            USERNAME
          </p>
          <input
            className="mt-2 w-full p-3 border-b focus:outline-none focus:border-[color:var(--theme-fourth)]"
            placeholder="Text your user name"
          />
        </label>
        <label>
          <p className="text-[color:var(--text-secondary)] text-body3">EMAIL</p>
          <input
            className="mt-2 w-full p-3 border-b focus:outline-none focus:border-[color:var(--theme-fourth)]"
            placeholder="Text your email"
          />
        </label>
        <label>
          <p className="text-[color:var(--text-secondary)] text-body3">
            PASSWORD
          </p>
          <input
            className="mt-2 w-full p-3 border-b focus:outline-none focus:border-[color:var(--theme-fourth)]"
            placeholder="Text your password"
          />
        </label>
        <p>
          See our Privacy{" "}
          <Link to="/">
            <span className="text-title1">Policy</span>
          </Link>{" "}
          for more details.
        </p>
        <div className="flex gap-2 text-[color:var(--text-secondary)] text-body2">
          <input type="checkbox" />
          <p>Please contact me via e-mail</p>
        </div>
        <div className="flex gap-2 text-[color:var(--text-secondary)] text-body2">
          <input type="checkbox" />
          <p>I have read and accept the Terms and Conditions</p>
        </div>
        <div className="my-4 text-[color:var(--text-secondary)] text-body2">
          <p>
            This site is protected by reCAPTCHA and the Google Privacy Policy
            and Terms of Service apply.
          </p>
        </div>
        <button className="bg-[color:var(--theme-secondary)] text-white text-title1 p-5 rounded-lg">
          Create Account
        </button>
        <button className="lg:hidden text-title2 w-fit" onClick={handleClickLogin}>
          Login
        </button>
      </form>
      
    </div>
  );
};

export default RegisterPage;
