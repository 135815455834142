import React from "react";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { authActions } from "../../../../store/auth/auth.slice";
import Logo from "../../../../image/logo.png";

const LoginPage = () => {
  const { loginModalClick } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const handleClickRegister = () => {
    dispatch(authActions.setLoginModalClick(false));
    dispatch(authActions.setRegisterModalClick(true));
  };
  const handleClickResetPwd = () => {
    dispatch(authActions.setLoginModalClick(false));
    dispatch(authActions.setResetPwdModalClick(true));
  };
  const handleLoginSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };
  return (
    <div
      className={
        loginModalClick
          ? "transition duration-700 flex w-full bg-[#f8f8f8] opacity-1"
          : "opacity-0 z-[-100] absolute"
      }
    >
      <div className="hidden lg:flex flex-col w-1/2 bg-[color:var(--theme-thrid)] border-r border-slate-300 p-10 ">
        <p className="text-title1">Welcome</p>
        <div className="grow flex justify-center items-center">
          <img src={Logo} width={"70%"} alt="" />
        </div>
        <button className="text-title2 w-fit" onClick={handleClickRegister}>
          Register
        </button>
      </div>
      <form
        onSubmit={handleLoginSubmit}
        className="w-full lg:w-1/2 p-10 flex flex-col gap-5"
      >
        <p className="text-title1">Login</p>
        <label>
          <p className="text-[color:var(--text-secondary)] text-body3">Email</p>
          <input
            className="mt-2 w-full p-3 border-b focus:outline-none focus:border-[color:var(--theme-fourth)]"
            placeholder="Text your email"
          />
        </label>
        <label>
          <p className="text-[color:var(--text-secondary)] text-body3">
            Password
          </p>
          <input
            className="mt-2 w-full p-3 border-b focus:outline-none focus:border-[color:var(--theme-fourth)]"
            placeholder="Text your password"
          />
        </label>
        <div className="flex gap-2 text-[color:var(--text-secondary)] text-body2">
          <input type="checkbox" />
          <p>Keep me logged in</p>
        </div>
        <button className="bg-[color:var(--theme-secondary)] text-white text-title1 p-5 rounded-lg">
          Login Now
        </button>
        <div className="flex w-full justify-between lg:justify-end">
          <button className="lg:hidden text-title2 w-fit" onClick={handleClickRegister}>
            Register
          </button>
          <button
            className="w-fit border-b border-[color:var(--theme-secondary)] text-body2 pb-[0.5px]"
            onClick={handleClickResetPwd}
          >
            Forgot your password?
          </button>
        </div>
      </form>
    </div>
  );
};

export default LoginPage;
