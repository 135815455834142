import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface authProps {
    loginModalClick : boolean,
    registerModalClick : boolean,
    resetPwdModalClick : boolean
}
const initialState = {
    loginModalClick : false,
    registerModalClick : false,
    resetPwdModalClick : false
}

const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers:{
        setLoginModalClick(state, action) {
            state.loginModalClick = action.payload
        },
        setRegisterModalClick(state, action) {
            state.registerModalClick = action.payload
        },
        setResetPwdModalClick(state, action) {
            state.resetPwdModalClick = action.payload
        }
    }
})
export const authActions = authSlice.actions;
export default authSlice;