import { FC, useState } from "react";
import NuCarousel from "./component/NuCarousel";
import { Link } from "react-router-dom";
import GooglePlay from "../../../image/googleplay2.png";
import AppleStore from "../../../image/applestore2.png";
import ThreeDIcon from "../../../image/3dIcon.svg";
import PhotoIcon from "../../../image/photoIcon.svg";
import SynthesizerIcon from "../../../image/SynthesizerIcon.svg";
import VedioImg1 from "../../../image/videoimg/vedioImg1.png";
import VedioImg2 from "../../../image/videoimg/vedioImg2.png";
import VedioImg3 from "../../../image/videoimg/vedioImg3.png";
import VedioImg4 from "../../../image/videoimg/vedioImg4.png";
import Mobile1 from "../../../image/mobile1.png";
import Mobile2 from "../../../image/mobile2.png";
import Mobile3 from "../../../image/mobile3.png";
import Gallery01 from "../../../image/gallery01.png";
import Gallery02 from "../../../image/gallery02.png";
import Gallery03 from "../../../image/gallery03.png";
import Gallery04 from "../../../image/gallery04.png";
import { useTranslation } from "react-i18next";
import { AiImagCard, CarouselCard } from "./component/Card";

const demoBoxArr = [
  {
    img: ThreeDIcon,
    title: "feature-title-1",
    text: "feature-content-1",
  },
  {
    img: PhotoIcon,
    title: "feature-title-2",
    text: "feature-content-2",
  },
  {
    img: SynthesizerIcon,
    title: "feature-title-3",
    text: "feature-content-3",
  },
];
const demoBoxTextAreaStyle =
  "flex flex-col w-full rounded-xl overflow-hidden shadow-member";
const Home: FC = () => {
  const [vedioIndex, setVedioIndex] = useState<string>("1");
  const [spinnerAct, setSpinnerAct] = useState<boolean>(false);
  const [round, setRound] = useState<boolean>(false);
  const { t } = useTranslation();
  const handleClickVedioImg = (e: React.MouseEvent<HTMLButtonElement>) => {
    const index = e.currentTarget.dataset.set || "1";
    setVedioIndex(index);
    setRound(true);
    setTimeout(() => {
      setRound(false);
    }, 500);
  };
  const mobileImgArr = [Mobile1, Mobile2, Mobile3];
  let vedioArray = mobileImgArr.map((e, id) => {
    return <CarouselCard key={id} mobileItem={e} />;
  });
  return (
    <div className="grow pb-12">
      <div className="w-full flex flex-col sm:flex-row justify-center items-center gap-10 sm:gap-20 mt-[5%] text-center sm:text-left py-[20%] sm:py-[6%] bg-homeBg bg-blend-hue bg-[color:var(--bg-primary)] bg-[center_top_-0.1rem] xm:bg-[center_top_-3rem] md:bg-[center_top_-5rem] lg:bg-[center_top_-10rem] xl:bg-[center_top_-15rem] 2xl:bg-[center_top_-18rem] 3xl:bg-[center_top_-18rem] bg-cover">
        <div className="sm:w-2/6 flex flex-col justify-center">
          <p className="text-white text-[32px] lg:text-headline1">
            {t("header-title")}
          </p>
          <p className="text-headline3 text-white">{t("header-content")}</p>
        </div>
        <div className="w-[300px] ">
          <div className="flex flex-col bg-cover p-7 bg-vedioCard h-[378px] ">
            <div className="bg-white grow">
              {round ? (
                <div className="w-full h-full flex justify-center items-center ">
                  AI Generation in Progress...
                </div>
              ) : (
                <div className={`p-1`}>
                  <video autoPlay={true} muted={true} loop>
                    <source
                      src={require(`../../../vedio/vedio${vedioIndex}.mp4`)}
                      type="video/mp4"
                    />
                  </video>
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-3 mt-4 w-full">
            <button
              className="w-3/12 rounded-xl overflow-hidden"
              data-set="1"
              onClick={handleClickVedioImg}
            >
              <img src={VedioImg1} width={"100%"} alt="" />
            </button>
            <button
              className="w-3/12 rounded-xl overflow-hidden"
              data-set="2"
              onClick={handleClickVedioImg}
            >
              <img src={VedioImg2} width={"100%"} alt="" />
            </button>
            <button
              className="w-3/12 rounded-xl overflow-hidden"
              data-set="3"
              onClick={handleClickVedioImg}
            >
              <img src={VedioImg3} width={"100%"} alt="" />
            </button>
            <button
              className="w-3/12 rounded-xl overflow-hidden"
              data-set="4"
              onClick={handleClickVedioImg}
            >
              <img src={VedioImg4} width={"100%"} alt="" />
            </button>
          </div>
        </div>
      </div>
      <div className="w-full p-2 pt-[6%]">
        <div className="flex flex-col items-center">
          <p className="text-center text-headline2">{t("feature-title")}</p>
          <p className="text-center text-headline3 mt-6 w-full sm:w-3/6 text-[color:var(--text-third)]">
            {t("feature-description")}
          </p>
        </div>
        <div className="m-auto w-[90%] lg:w-[65%] lg:mb-8 py-10 grid sm:flex items-stretch gap-10">
          {demoBoxArr.map((d, i) => {
            return (
              <div key={i} className="flex flex-1">
                <div
                  className={`${demoBoxTextAreaStyle} flex flex-col justify-between`}
                >
                  <div className="h-[7px] w-full bg-gradientGreytoPurpleBg" />
                  <div className="pb-10 pt-[13%] px-5 flex flex-col items-center">
                    <img height={"75px"} src={d.img} alt="" />
                    <p className="text-title1 text-[color:var(--text-primary)] pt-8 pb-3">
                      {t(d.title)}
                    </p>
                    <p className="text-body1 text-center text-[color:var(--text-third)]">
                      {t(d.text)}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="p-2 bg-gradientImageBg bg-bottom bg-no-repeat">
        <div className="flex flex-col items-center">
          <p className="text-center text-headline2">{t("app-title")}</p>
          <p className="text-center text-headline3 mt-6 w-full sm:w-3/6 text-[color:var(--text-third)]">
            {t("app-content")}
          </p>
        </div>
        <div className="flex justify-center">
          <div className="w-full sm:w-1/2">
            <NuCarousel
              components={vedioArray}
              autoReplay={spinnerAct}
              className={`sm:hidden`}
              showNum={1}
            />
            <NuCarousel
              components={vedioArray}
              autoReplay={spinnerAct}
              className={`hidden sm:block xl:hidden`}
              showNum={2}
            />
            <NuCarousel
              components={vedioArray}
              autoReplay={spinnerAct}
              className={`hidden xl:block 3xl:hidden`}
              showNum={3}
            />
            <NuCarousel
              components={vedioArray}
              autoReplay={spinnerAct}
              className={`hidden 3xl:block`}
              showNum={3}
            />
          </div>
        </div>
        <p className="text-headline2 text-center mt-10">{t("download")}</p>
        <div className="lg:mb-8 py-12 flex items-center justify-center">
          <Link
            to="https://apps.apple.com/tw/app/nuwo/id6452946598"
            target="_blank"
            className="flex justify-center"
          >
            <img src={AppleStore} width={"70%"} alt="" />
          </Link>
          <Link
            to="https://play.google.com/store/apps/details?id=com.numiner.nuwo"
            target="_blank"
            className="flex justify-center"
          >
            <img src={GooglePlay} width={"70%"} alt="" />
          </Link>
        </div>
      </div>
      <div className="px-2 py-8 flex flex-col items-center gap-8">
        <p className="text-headline2 text-center">{t("ai-img-title")}</p>
        <p className="text-headline3 text-center w-full sm:w-3/6 text-[color:var(--text-third)]">
          {t("ai-img-content")}
        </p>
        <button className="w-fit bg-[color:var(--theme-fifth)] text-[28px] text-white px-8 py-1 rounded-[100px]">
          Browser more
        </button>
        <div className="grid sm:flex gap-10">
          <AiImagCard title={"christmas"} image={Gallery01}/>
          <AiImagCard title={"valentine"} image={Gallery02}/>
          <AiImagCard title={"family"} image={Gallery03}/>
          <AiImagCard title={"pets"} image={Gallery04}/>
        </div>
      </div>
    </div>
  );
};
export default Home;
