import React from "react";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { authActions } from "../../../../store/auth/auth.slice";
import Logo from '../../../../image/logo.png'

const ResetPasswordPage = () => {
  const { resetPwdModalClick } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const handleClickRegister = () => {
    dispatch(authActions.setResetPwdModalClick(false));
    dispatch(authActions.setRegisterModalClick(true));
  };
  const handleClickLogin = () => {
    dispatch(authActions.setResetPwdModalClick(false));
    dispatch(authActions.setLoginModalClick(true));
  };
  const handleLoginSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };
  return (
    <div
      className={
        resetPwdModalClick
          ? "transition duration-700 flex w-full bg-[#f8f8f8] opacity-1"
          : "opacity-0 z-[-100] absolute"
      }
    >
      <div className="hidden lg:flex flex-col w-1/2 bg-[color:var(--theme-thrid)] border-r border-slate-300 p-10 ">
        <p className="text-title1">Welcome</p>
        <div className="grow flex justify-center items-center">
          <img src={Logo} width={'70%'} alt="" />
        </div>
        <button className="text-title2 w-fit" onClick={handleClickRegister}>
          Register
        </button>
      </div>
      <form
        onSubmit={handleLoginSubmit}
        className="w-full lg:w-1/2 p-10 flex flex-col gap-5"
      >
        <p className="text-title1">Forgot your password?</p>
        <p className="text-body1 my-4">Enter your username or email address and we will send you a link to reset your password.</p>
        <label>
          <p className="text-[color:var(--text-secondary)] text-body3">EMAIL OR USERNAME</p>
          <input
            className="mt-2 w-full p-3 border-b focus:outline-none focus:border-[color:var(--theme-fourth)]"
            placeholder="Text your email or user name"
          />
        </label>
        <button className="bg-[color:var(--theme-secondary)] text-white text-title1 p-5 rounded-lg mt-5">
          Reset Password
        </button>
        <div className="flex w-full justify-center">
          <button className="w-fit text-title1" onClick={handleClickLogin}>
            Back
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResetPasswordPage;
