import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface AiImageProps {
  title: string;
  image: string;
}
export const AiImagCard = (props: AiImageProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-[301px] h-[301px] bg-[color:var(--theme-fourth)] flex items-end overflow-hidden rounded-lg" style={{backgroundImage: `url(${props.image})`,backgroundSize: 'cover'}}>
      <div className="text-center w-full py-4 bg-white/30 text-white backdrop-brightness-50">
        {t(props.title)}
      </div>
    </div>
  );
};

interface CarouselCardProps {
  mobileItem: string;
}
export const CarouselCard = (props: CarouselCardProps) => {
  return (
    <div className="flex items-center justify-center p-7 lg:p-0 pointer-events-none">
      <div className="p-7">
        <div data-value={props.mobileItem}>
          <img src={props.mobileItem} alt="" />
        </div>
      </div>
    </div>
  );
};
