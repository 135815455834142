import React from "react";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { authActions } from "../../../store/auth/auth.slice";
import { LoginPage, RegisterPage, ResetPasswordPage } from "./components";
import { RxCross1 } from "react-icons/rx";

const AuthModal = () => {
  const { loginModalClick, registerModalClick, resetPwdModalClick } =
    useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const handleCloseModal = () => {
    dispatch(authActions.setLoginModalClick(false));
    dispatch(authActions.setRegisterModalClick(false));
    dispatch(authActions.setResetPwdModalClick(false));
  };
  return (
    <div
      className={
        loginModalClick || registerModalClick || resetPwdModalClick
          ? "fixed top-0 left-0 right-0 w-full h-full transition duration-500 bg-black/70 flex items-center justify-center overflow-y-auto z-[100] opacity-1"
          : "fixed z-[-100] opacity-0"
      }
    >
      <div className="w-11/12 sm:w-9/12 md:w-8/12 xl:w-7/12 flex items-center justify-center overflow-y-auto bg-slate-50 rounded-2xl">
        <LoginPage />
        <RegisterPage />
        <ResetPasswordPage />
      </div>
      <button
        className="absolute right-[30px] bottom-[10px] lg:right-[40px] lg:bottom-[50px] text-2xl rounded-lg p-5 text-white bg-[color:var(--theme-secondary)]"
        onClick={handleCloseModal}
      >
        <RxCross1 />
      </button>
      <button className="fixed top-0 left-0 right-0 w-full h-full z-[-1] cursor-default" onClick={handleCloseModal}/>
    </div>
  );
};

export default AuthModal;
